import React from "react"
import { navigate, graphql } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-dark.png"

const Contact = ({ data }) => {
  return (
    <React.Fragment>
      <PageTitle title={"Contact"} />
      <Layout footerBottom={true}>
        <div className="auditpartner-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Contact</a>
              </li>
            </ul>
          </div>
          <div className="contact">
            <div className="page__flex">
              <div className="contact__box">
                <h1>Contact</h1>
                <div className="contact-horizontal-line"></div>
                <div className="contact__box--block">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.datoCmsContact.text,
                    }}
                  ></div>
                  <div className="contact__box--block--button--container">
                    <div className="page__btn page__btn--blue js-popup contact__box--block--button">
                      <a onClick={() => navigate("/en/faq")}>Go to FAQs</a>
                    </div>
                    <div className="page__btn page__btn--blue js-popup contact__box--block--button">
                      <a
                        href="https://calendly.com/cyber-trust/free-advice"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        Schedule a Call
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default Contact

export const query = graphql`
  query ($locale: String!) {
    datoCmsContact(locale: { eq: $locale }) {
      text
    }
  }
`
